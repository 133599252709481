<template>
  <section class="mechanism-wrapper">
    <card title="机构管理">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="addMechanism"
        >新增</el-button
      >
      <el-table :data="hospitalList" border>
        <el-table-column
          prop="hid"
          align="center"
          width="50"
          label="编号"
        ></el-table-column>
        <el-table-column
          label="机构名称"
          align="center"
          prop="hname"
        ></el-table-column>
        <el-table-column
          label="负责人"
          align="center"
          prop="principal"
        ></el-table-column>
        <el-table-column
          label="负责人联系方式"
          align="center"
          prop="ptel"
        ></el-table-column>
        <el-table-column label="对应二维码" align="center" prop="url">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.qcode ? 'https://' + scope.row.qcode : ''"
              class="QR-wrapper"
              fit="fit"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          align="center"
          prop="comment"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="editMechanism(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="deleteMechanism(scope.$index, scope.row)"
              >删除</el-button
            >
          </template></el-table-column
        >
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="PageSize"
        layout="total, prev, pager, next,jumper"
        :total="totalCount"
        style="margin: 20px 0 0 30px"
      >
      </el-pagination>
    </card>
    <el-dialog title="新增机构" :visible.sync="dialogFormVisible">
      <el-form
        :model="hospital"
        label-position="left"
        class="mechanism-form"
        ref="form"
      >
        <el-form-item label="机构名称:" label-width="90px">
          <el-input
            v-model="hospital.hname"
            autocomplete="off"
            class="form-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="机构负责人:" label-width="90px">
          <el-input
            v-model="hospital.principal"
            autocomplete="off"
            class="form-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式:" label-width="90px">
          <el-input
            v-model="hospital.ptel"
            autocomplete="off"
            class="form-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:" label-width="90px">
          <el-input
            v-model="hospital.comment"
            autocomplete="off"
            class="form-input"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitHospital()">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import card from "../../components/card";
export default {
  components: {
    card
  },
  data() {
    return {
      hospital: {
        hname: "",
        principal: "",
        ptel: "",
        comment: ""
      },
      hospitalList: [],
      dialogFormVisible: false,
      // 默认显示第几页
      currentPage: 1,
      // 默认每页显示的条数（可修改）
      PageSize: 10,
      totalCount: 0,
      type: 0
    };
  },
  methods: {
    addMechanism() {
      this.dialogFormVisible = true;
      this.hospital = this.$options.data().hospital;
      this.type = 0;
    },
    editMechanism(index, row) {
      this.hospital = Object.assign({}, row);
      this.type = 1;
      this.dialogFormVisible = true;
    },
    deleteMechanism(index, row) {
      this.$confirm("确认删除此机构?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          url: "/deleteHospital",
          params: {
            id: row.hid
          }
        }).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.getHospital();
          } else {
            this.$message({
              type: "error",
              message: "机构信息获取失败"
            });
          }
        });
      });
    },
    submitHospital() {
      let url = this.type ? "/updateHospital" : "/addHospital";
      this.$axios({
        url: url,
        method: "post",
        transformRequest: [
          function(data) {
            return JSON.stringify(data);
          }
        ],
        data: this.hospital,
        headers: { "Content-Type": "application/json" }
      })
        .then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: this.type ? "修改机构信息成功" : "添加机构成功"
            });
            this.getHospital();
          } else {
            this.$message({
              type: "error",
              message: this.type ? "修改机构信息失败" : "添加机构失败"
            });
          }
          this.dialogFormVisible = false;
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err
          });
        });
    },
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val;
      this.getHospital();
    },
    getHospital() {
      return this.$axios({
        url: "/getAllHospital",
        params: {
          page: this.currentPage
        }
      })
        .then(res => {
          if (res.data.code == 200) {
            this.PageSize = res.data.data.pageSize;
            this.totalCount = res.data.data.total;
            this.hospitalList = res.data.data.list;
          } else {
            this.$message({
              type: "error",
              message: "机构信息获取失败"
            });
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: "订单信息获取失败"
          });
        });
    }
  },
  created() {
    this.$openLoading();
    this.getHospital().then(
      setTimeout(() => {
        this.$openLoading().close();
      }, 200)
    );
  }
};
</script>

<style lang="less">
.el-button + .el-button {
  margin-left: 10px;
}
.QR-wrapper {
  height: 80px;
  width: 80px;

  .image-slot {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    text-align: center;
  }
}

.mechanism-form {
  margin: 0 auto;
  width: 76%;

  .form-input {
    margin: 0 auto;
  }
}
</style>
